// Servicios
.main-servicios{
  .servicios{
    position: relative;
    top: -80px;
    .h3{
      color: $black;
      font-size: 30px;
      @media (max-width: 991px) {
        font-size: 25px;
      }
    }
    a{
      transition: all 1s ease;
      &:hover{
        text-decoration: none;
        transition: all 1s ease;
        .image{
          overflow: hidden;
          transition: all 1s ease;
          img{
            transition: all 1s ease;
            transform: scale(1.2);
          }
        }
        .h3{
          color: $basque-01;
        }
      }
    }
  }
}