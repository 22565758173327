// Header
.header{
  position: relative;
  background-color: $black;
  z-index: 4;
  .container{
    position: relative;
    z-index: 4;
    padding: 2rem 0;
    @media (max-width: 1300px) {
      padding: 2rem;
    }
  }
  .montserrat{
    font-family: 'Montserrat Medium';
  }
  .menu-top{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 8;
  }
  .open {
    display: block;
    transform: rotate(360deg);
    transition: all 4s ease-out;
  }
  .nav-icon {
    height: 35px; 
    width: 35px;
    position: relative;
    margin: 0px 10px;
    float: right;
    z-index: 1000;
    cursor: pointer;
    .icon{
      position: absolute;
      width: 20px;
      height: 20px;
      right: 7px;
      top: 7px;
      color: $white;
      z-index: 2;
    }
  }
  .nav-icon div {
    width: 35px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .nav-icon div::before,
  .nav-icon div::after {
    opacity: 0;
    content: "";
    height: 2px;
    width: 60%;
    right: 7px;
    position: absolute;
    background: white;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }
  .nav-icon div::before {
    top: -10px;
  }
  .nav-icon div::after {
    top: 10px;
  }
  .cross.active .span::before {
    opacity: 1;
    top: 15px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .cross.active .span::after {
    opacity: 1;
    top: 15px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .cross.active .span {
    background: $black;
    z-index: 3;
  }
}
.bg-black-menu {
  display: none;
  top: 0;
  position: absolute;
  max-width: 400px;
  height: 100%;
  background: $black;
  list-style: none;
  margin: 0;
  right: 0;
  transform-origin: top left;
  transform: translateX(100%);
  transition: all 4s ease-out;
  transform-style: preserve-3d;
  z-index: 5;
  padding: 0 1rem 5rem;
  @media (max-width: 991px) {
    padding: 4rem 1rem 5rem;
  }
  .row{
    padding: 6rem 1rem 0;
    @media (max-width: 991px) {
      padding: 3rem 1rem 0;
    }
  }
  li a {
    opacity: 1;
    display: block;
    color: white;
    text-decoration: none;
    transition: all 0.5s linear;
    margin: 0;
  }
  .menu{
    a{
      font-size: 28px;
      &:hover{
        text-decoration: underline;
      }
      @media (max-width: 991px) {
        font-size: 22px;
      }
    }
  }
  .idiomas{
    ul{
      flex-direction: row;
      li{
        font-size: 20px;
        margin-right: 1rem;
        .active{
          text-decoration: underline;
        }
      }
    }
  }
  .info{
    h4{
      font-size: 20px;
      font-weight: 600;
    }
    p{
      margin-bottom: 0;
    }
  }
}
.search {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 13px;
  svg{
    position: relative;
    width: 18px;
    height: 18px;
    color: $black;
    top: 1px;
    left: -1px;
  }
}
.searchTerm {
  width: 100%;
  border: 2px solid $white;
  border-right: none;
  padding: 10px;
  height: 36px;
  border-radius: 0;
  outline: none;
  color: $white;
  background-color: $black;
}
.searchTerm:focus{
  color: $white;
}
.searchButton {
  cursor: pointer;
  width: 40px;
  height: 36px;
  border: 1px solid $white;
  background: $white;
  text-align: center;
  color: $black;
  border-radius: 0;
  font-size: 20px;
}