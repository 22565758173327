// Contacto
.main-contacto{
  .mapa{
    padding: 0;
  }
  .form-control {
    width: 100%;
    height: 42px;
    outline: 0;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #999;
    margin-bottom: 2rem;
    &.error {
      margin-bottom: 0;
    }
  }
  .invalid-feedback {
    margin-bottom: 2rem;
  }
  textarea{
    height: 85px!important;
  }
  .label, .form-control:placeholder-shown ~ .label {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 4px;
    transition: 0.2s ease all;
    color: $black;
    font-weight: 200;
    font-size: 18px;
  }
  .form-control:focus ~ .label, .form-control:not(:placeholder-shown) ~ .label {
    top: -15px;
    bottom: 5px;
    left: 20px;
    font-weight: 300;
    color: $basque-01;
    transform: scale(0.6);
    transform-origin: left top;
  }
  .sub-area {
    text-align: center;
    margin-top: 50px;
  }
  .sub-area button {
    color: white;
    background: #333;
    border: none;
    font-weight: 100;
    padding: 10px 40px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    outline: none;
  }
  .sub-area button:hover {
    transform: translateY(2px);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.7);
  }
  .legales{
    font-size: 14px;
  }
  .form-check-label{
    font-size: 14px;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
  a{
    color: $basque-01;
  }
  .col-12{
    padding: 0;
  }
}