// Masthead
.masthead{
  position: relative;
  padding: 8rem 5rem 12rem;
  h1{
    font-family: 'Montserrat Medium';
  }
  .breadcrumb{
    background-color: transparent;
    color: $white;
    font-family: 'Montserrat Regular';
    a{
      color: $white;
    }
    .breadcrumb-item {
      &.active {
        font-weight: bold;
      }
      + .breadcrumb-item {
        color: $white;
        &::before {
          color: $white;
        }
      }
    }
  }
  @media (max-width: 991px) {
    h1{
      font-size: 55px;
    }
  }
  @media (max-width: 600px) {
    h1{
      font-size: 45px;
    }
    .breadcrumb{
      font-size: 12px;
    }
  }
  @media (max-width: 414px) {
    padding: 8rem 2rem 12rem;
    h1{
      font-size: 37px;
    }
  }
}