// Inversores
.main-inversores{
  .bloque-texto{
    .col-md-10{
      column-count: 2;
    }
  }
  .colaboradores.detalle{
    padding-top: 5rem;
  }
  .secciones{
    position: relative;
    .box{
      display: flex;
      justify-content: center;
      overflow: hidden;
      margin: 0 auto;
      .text{
        position: absolute;
        color: $white;
        bottom: 30px;
        left: 30px;
        z-index: 4;
      }
      .h4{
        color: $white;
        font-size: 30px;
      }
    }
  }
}