// Aplicaciones
.main-aplicaciones{
  .bloque-intro{
    position: relative;
    background-color: $basque-01;
    padding: 4rem 0;
    svg, .h2, p, li{
      color: $white;
    }
  }
  .aplicaciones{
    position: relative;
    .h4{
      text-transform: uppercase;
    }
  }
  .soluciones{
    justify-content: flex-start;
    padding: 0;
    .box{
      margin: 0;
      @media (max-width: 991px) {
        margin: 0 auto;
      }
    }
  }
  .pagination{
    justify-content: center;
    .page-link{
      border: 0;
      color: $black;
      opacity: 0.4;
      background-color: $white;
    }
    .active{
      .page-link{
        color: $basque-01;
        font-weight: 800;
        opacity: 1;
      }
    }
  }
}