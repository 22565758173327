/*
  # Background image with object-fit
*/
.seccion{
  padding: 5rem 0;
  overflow: hidden;
}
// # Background image with object-fit
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; // esto va ligado con el plugin de https://github.com/fregante/object-fit-images
  /* Si se quiere la posición:
  object-position: bottom;
  font-family: 'object-fit: cover; object-position: bottom;';
  */
}
.over{
  background: rgba(0,0,0,0) -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(0,0,0,.6))) repeat scroll 0 0;
  background: rgba(0,0,0,0) linear-gradient(transparent,rgba(0,0,0,.6)) repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.logotipo{
  cursor: pointer;
  img{
    max-width: 250px;
    height: auto;
    @media (max-width: 414px) {
      max-width: 200px;
    }
  }
}
// # Opacities
@each $opacity in $opacities {
  .opacity-#{$opacity} {
   opacity: #{$opacity/100};
  }
}
body{
  font-family: 'Montserrat Regular';
  color: #fff;
}
.main-container{
  background-color: #000;
}
.container{
  position: relative;
  max-width: 1250px;
  z-index: 2;
}
.list-group{
  list-style: none;
}
a{
  color: #2fbbaf;
  cursor: pointer;
  text-decoration: none;
  transition: all 1s ease;
  &:hover{
    transition: all 1s ease;
    .bg-image{
      transition: all 1s ease;
      transform: scale(1.2);
    }
    .over{
      background: rgba(0,0,0,0) -webkit-gradient(linear,left top,left bottom,from(transparent),to(rgba(15,151,119,.8))) repeat scroll 0 0;
      background: rgba(0,0,0,0) linear-gradient(transparent,rgba(15,151,119,.8)) repeat scroll 0 0;
    }
  }
}
p a{
  color: #2fbbaf!important;
}
strong{
  font-family: 'Montserrat Bold';
}
// Encabezados
h1,h2,h3,h4,h5,li,p{
  color: #fff;
}
.basquevolt{
  .icon{
    fill: $white;
    opacity: 0.2;
    width: 20px;
    height: 20px;
  }
}
.separator {
  height: 2px;
  margin: 20px 0px;
  background: #eeeeee;
  position: relative;
}
.separator:after {
  position: absolute;
  left: 0;
  bottom:0;
  height: 2px;
  width: 50px;
  background: #2fbbaf;
  content:"";
}
.h1{
  font-size: 70px;
  font-family: 'Montserrat Bold';
  @media (max-width: 991px) {
    font-size: 42px;
  }
  @media (max-width: 600px) {
    font-size: 35px;
  }
}
.h2{
  font-size: 50px;
  font-family: 'Montserrat Bold';
  @media (max-width: 991px) {
    font-size: 38px;
  }
  @media (max-width: 600px) {
    font-size: 30px;
  }
}
.h3{
  font-size: 34px;
  font-family: 'Montserrat SemiBold';
  @media (max-width: 991px) {
    font-size: 28px;
  }
  @media (max-width: 600px) {
    font-size: 22px;
  }
}
.h4{
  font-size: 20px;
  font-family: 'Montserrat SemiBold';
  @media (max-width: 991px) {
    font-size: 17px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
}
.h5{
  font-size: 16px;
  font-family: 'Montserrat SemiBold';
  color: $basque-01;
  @media (max-width: 991px) {
    font-size: 15px;
  }
}
.lead{
  font-size: 17px;
  opacity: 0.7;
}
.image{
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.full-image{
  width: 100%;
  height: auto;
}
.block-text{
  a{
    color: $basque-01;
  }
}