// SLIDER
.banner__slider{
  position: relative;
  .slide__content--headings{
    padding: 2rem;
    @media (min-width: 768px) {
      display: flex;
      padding: 4rem;
    }
    @media (max-width: 767px) {
      display: flex;
      padding: 4rem 0;
    }
  }
  .slide__content {
    position: absolute;
    bottom: 50px;
    background-color: rgba(255, 255, 255, .8);
    @media (max-width: 1600px) {
      left: 15%;
    }
    @media (min-width: 1600px) {
      left: 15%;
    }
    @media (max-width: 1599px) {
      left: 11%;
      bottom: 90px;
    }
    @media (max-width: 1480px) {
      left: 7%;
      bottom: 120px;
    }
    @media (max-width: 1380px) {
      left: 2%;
      bottom: 150px;
    }
    @media (max-width: 1300px) {
      position: relative;
      bottom: 0;
    }
    &--headings {
      display: flex;
      flex-wrap: wrap;
      color: $black;
      .title{
        color: $black;
        font-family: 'Montserrat Bold';
        font-size: 28px;
        font-weight: bold;
        width: 40%;
        padding-right: 2rem;
        span{
          color: $basque-01;
        }
      }
      .text{
        width: 60%;
        h1,h2,h3,h4,h5,li,p{
          color: $black;
        }
      }
    }
    @media (max-width: 767px) {
      position: relative;
      bottom: 0;
    }
    @media (max-width: 767px) {
      .title{
        width: 100%;
        padding: 0;
        margin-bottom: 1rem;
      }
      .text{
        width: 100%;
      }
    }
  }
}
.slider-home, .slide {
  @media (min-width: 1300px) {
    height: 880px;
  }
  @media (max-width: 1299px) {
    .slide__img{
      position: relative;
      height: auto;
      video{
        width: 100%;
        height: auto;
      }
      img{
        width: 100%;
        height: auto;
      }
    }
  }
}
.slide {
  position: relative;
  display: flex!important;
  flex-wrap: wrap;
  justify-content: center;
  .slide__img {
    overflow: hidden;
    img {
      opacity: 1 !important;
      animation-duration: 3s;
      transition: all 1s ease;
    }
    @media (min-width: 1300px) {
      position: absolute;
      width: 100%;
      height: auto;
      left: 0;
      img, video{
        width: 100%;
        height: auto;
      }
    }
    @media (max-width: 1299px) {
      width: 100%;
      height: auto;
      position:relative;
      bottom: 0;
      img {
        max-width: auto;
        height: 100%;
      }
    }
  }
}
.slick-nav {
    --active: #fff;
    --border: rgba(255, 255, 255, .12);
    width: 44px;
    height: 44px;
    position: absolute;
    cursor: pointer;
    top: calc(50% - 44px);
    &.prev-arrow {
      left: 3%;
      transform: scaleX(-1);
      z-index: 999;
    }
    &.next-arrow{
      left: auto;
      right: 3%;
    }
    i {
        display: block;
        position: absolute;
        margin: -10px 0 0 -10px;
        width: 20px;
        height: 20px;
        left: 50%;
        top: 50%;
        &:before,
        &:after {
            content: '';
            width: 10px;
            height: 2px;
            border-radius: 1px;
            position: absolute;
            left: 50%;
            top: 50%;
            background: var(--active);
            margin: -1px 0 0 -5px;
            display: block;
            transform-origin: 9px 50%;
        }
        &:before {
            transform: rotate(-40deg);
        }
        &:after {
            transform: rotate(40deg);
        }
    }
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
        border-radius: 50%;
        border: 2px solid var(--border);
    }
    svg {
        width: 44px;
        height: 44px;
        display: block;
        position: relative;
        z-index: 1;
        color: var(--active);
        stroke-width: 2px;
        stroke-dashoffset: 126;
        stroke-dasharray: 126 126 0;
        transform: rotate(0deg);
    }
}
// Quiénes somos
.nosotros{ 
  position: relative;
  padding: 8rem 0 3rem;
  #nbr{
    font-size: 40px;
    font-weight: 600;
    font-family: 'Montserrat Bold';
    color: $basque-01;
    @media (max-width: 600px) {
      font-size: 25px;
    }
  }
  .pd-right{
    padding-right: 4rem;
  }
  .d-flex{
    flex-wrap: wrap;
    margin: 4rem 0;
  }
  .h2{
    font-size: 35px;
  }
  .box{
    width: 50%;
    padding: 4rem;
    &:nth-child(3n+1){
      background-color: #F6F2F2;
    }
    @media (max-width: 767px) {
      padding: 4rem 1rem;
    }
  }
  .text{
    color: $white;
    font-style: italic;
  }
}
// Grupo basquevolt
.grupo{
  .container-fluid{
    padding: 0;
  }
  .d-flex{
    align-items: center;
    .col-lg-6{
      padding: 0;
      overflow: hidden;
    }
    .text{
      padding: 3rem;
      @media (min-width: 1201px) {
        padding-left: 8rem;
        padding-top: 3rem;
        padding-bottom: 3rem;
        padding-right: 3rem;
      }
      @media (min-width: 992px) {
        max-width: 600px;
      }
    }
    &:nth-child(2n){
      flex-direction: row-reverse;
      .text{
        @media (min-width: 1201px) {
          padding-right: 8rem;
          padding-left: 3rem;
        }
      }
    }
  }
}
// Soluciones
.soluciones{
  position: relative;
  padding: 4rem 0;
  @media (min-width: 1201px) {
    .row{
      justify-content: space-between;
    }
  }
  @media (max-width: 1200px) {
    .row{
      justify-content: center;
    }
  }
  .box{
    display: flex;
    justify-content: center;
    overflow: hidden;
    max-width: 450px;
    margin: 0 auto;
    .text{
      position: absolute;
      color: $white;
      bottom: 30px;
      left: 30px;
      z-index: 4;
    }
    h3{
      color: $white;
    }
  }
}
// Inversores y colaboradores
.colaboradores{
  //@at-root: 1px solid $black;
  padding-bottom: 5rem;
  // background-color: #e8e8e8;
  .logo{
    padding: 1rem;
  }
  .box{
    border: 1px solid #000;
  }
}
// Por qué Basquevolt
.porque{
  position: relative;
  padding-bottom: 6rem;
  .box-white{
    background-color: $white;
    max-width: 600px;
    padding: 4rem;
    right: -50px;
    position: absolute;
    z-index: 3;
    top: 30%;
    @media (max-width: 767px) {
      right: 0;
      padding-top: 0;
    }
  }
  .image{
    z-index: 2;
  }
  &::after{
    content: "";
    position: absolute;
    background-color: #e4e4e4;
    width: 100%;
    height: 400px;
    bottom: 0;
  }
}
// Noticias
.noticias{
  .separator:after {
    left: 48%;
  }
  .fecha{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    text-transform: uppercase;
    .numero{
      font-weight: 700;
      font-size: 30px;
      line-height: 25px;
    }
    .mes{
      font-size: 15px;
      margin-bottom: 1rem;
    }
  }
  .box{
    color: $white;
    text-decoration: none;
    transition: all 1s ease;
    &:hover{
      transition: all 1s ease;
      .h4{
        color: darken($basque-01, 20%);
        transition: all 1s ease;
      }
      img{
        transition: all 1s ease;
        transform: scale(1.2);
      }
    }
  }
  .h4{
    color: $basque-01;
    font-weight: 600;
  }
  .btn-basque-03{
    font-size: 17px;
  }
}
// Trabaja con nosotros
.trabaja{
  position: relative;
  overflow: hidden;
  padding: 8rem;
  .bg-image{
    position: absolute;
    width: 100%;
    left: 0;
    top: -35%;
  }
  @media (max-width: 1400px) {
    .bg-image{
      top: 0;
    }
  }
  @media (max-width: 1080px) {
    .bg-image{
      width: auto;
      height: 100%;
    }
  }
  @media (max-width: 575px) {
    padding: 10rem 2rem;
    .bg-image{
      width: auto;
    }
  }
}