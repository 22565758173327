// comunicacion
.main-comunicacion{
  .nav-categories{
    padding: 4rem 0;
    .nav-item{
      font-family: 'Montserrat Bold';
      text-transform: uppercase;
    }
    .nav-link{
      color: $white;
    }
    .nav-link.active{
      color: $basque-01;
    }
  }
  .comunicacion{
    position: relative;
    .row{
      margin-bottom: 8rem;
    }
    .box{
      display: flex;
      justify-content: center;
      overflow: hidden;
      margin: 0 auto;
      .text{
        position: absolute;
        color: $white;
        bottom: 30px;
        left: 30px;
        z-index: 4;
      }
      .h4{
        color: $white;
        font-size: 30px;
      }
    }
  }
  .noticias {
    a {
      h3 {
        color: $basque-01;
      }
    }
    .box {
      &:hover {
         h3 {
           color: darken($basque-01, 20%);
           transition: all 1s ease;
         }
      }
    }
  }
}
// Noticias
.main-noticias{
  h1{
    font-size: 2.5rem;
  }
  .noticias{
    position: relative;
    top: -80px;
    .h4{
      color: $white;
    }
  }
  .bloque-intro{
    position: relative;
    padding: 4rem 0 0;
  }
  @media (max-width: 600px) {
    .masthead{
      padding:0 2rem 2rem;
    }
    h1{
      font-size: 1.2rem;
    }
    .lead {
      font-size: 14px;
    }
  }
}