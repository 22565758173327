.block{
  padding: 4rem 0;
  &.block-intro {
    position: relative;
  }
  &.block-bg {
    color: $white;
    @media (min-width: 1200px) {
      padding: 4rem 8rem;
    }
    @media (max-width: 1199px) {
      padding: 2rem 3rem;
    }
    @media (max-width: 767px) {
      .col-md-5{
        margin-bottom: 3rem;
      }
    }
    &.bg-blue {
      background-color: $basque-02;
    }
    &.bg-green {
      background-color: $basque-01;
    }
  }
  &.block-type-3{
    .main {
      display:block;
      margin:0 auto;
      img {
        cursor: grab;
        &:active {
          cursor: grabbing;
        }
      }
    }
    img{
      width: 100%;
    }
    .slider-nav {
      padding-left: 50px;
      padding-right: 50px;
      .slick-slide {
        padding-left: 1rem;
        padding-right: 1rem;
        img {
          opacity: .4;
          cursor: pointer;
        }
        &.slick-current {
          img {
            opacity: 1;
          }
        }
      }
      .slick-nav {
        &::after, &::before{
          border: 0;
        }
        &.slick-arrow {
          top: 50%;
          transform: translateY(-50%);
          color: $black;
          &.prev-arrow{
            left: 0;
            transform: translateY(-50%) scaleX(-1);
          }
          &.next-arrow{
            right: 0;
          }
          i {
            &::after, &::before {
              background: $black;
              &:hover {
                background: lighten($black, 25);
              }
            }
          }
        }
      }
    }
  }
  &.block-type-4{
    li{
      margin: 1rem;
      &::marker{
        font-size: 50px;
        font-family: 'Montserrat Bold';
        color: $basque-01;
      }
      .h4{
        font-size: 27px;
      }
      @media (min-width: 992px) {
        width: 45%;
      }
    }
  }
  &.block-type-5{
    background-color: $fondos;
    color: $white;
    .slider-for{
      height: auto;
    }
    .slick-nav{
      position: absolute!important;
      top: 170px!important;
    }
    .slick-nav.prev-arrow{
      left: -6px;
    }
    .slick-nav.next-arrow{
      left: 8%;
    }
    @media (min-width: 1200px) {
      padding: 8rem;
    }
    @media (max-width: 1199px) {
      padding: 5rem 3rem;
    }
    @media (max-width: 767px) {
      .col-md-5{
        margin-bottom: 3rem;
      }
    }
  }
}