// Trabaja con nosotros
.main-trabaja{
  .bloques{
    .row:nth-child(2n+1){
      flex-direction: row-reverse;
    }
    .col-md-5{
      padding: 4rem;
    }
    @media (max-width: 991px) {
      .col-md-5{
        padding: 2rem;
      }
    }
  }
}