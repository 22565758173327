// Productos
.main-productos{
  .productos{
    position: relative;
    .box{
      display: flex;
      justify-content: center;
      overflow: hidden;
      margin: 0 auto;
      .text{
        position: absolute;
        color: $white;
        bottom: 30px;
        left: 30px;
        z-index: 4;
      }
      .h4{
        color: $white;
        font-size: 30px;
      }
    }
  }
}