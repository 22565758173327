.validation-form{
  .invalid-feedback{
    display: block;
  }
}
.form-group{
  border: 0;
  border-radius: 0;
  color: $white!important;
  &::placeholder{
    color: $white!important;
  }
  .form-control{
    color: $white!important;
    border: 0;
    background-color: transparent;
  }
}
.border-bottom{
  border-radius: 0;
  border-bottom: 1px solid $white!important;
}
.form-check-label{
  font-size: 13px;
}
.recaptcha{
  font-size: 12px;
  padding-left: 0;
}