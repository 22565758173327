.pagination{
  justify-content: center;
  .page-link{
    border: 0;
    color: $black;
    opacity: 0.5;
    background-color: $white !important;
  }
  .active{
    .page-link{
      color: $basque-01 !important;
      font-weight: 800;
      opacity: 1;
      cursor: default;
    }
  }
  .disabled {
    .page-link {
      opacity: .2;
    }
  }
}