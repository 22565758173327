// Footer
.footer{
  background-color: $black;
  padding: 5rem 0 7rem;
  h3{
    font-size: 18px;
    color: $basque-01;
    font-family: 'Montserrat Medium';
  }
  .slogan{
    max-width: 300px;
  }
  a{
    color: $white;
  }
  .list-unstyled{
    margin-right: 1rem;
  }
  .montserrat{
    font-family: 'Montserrat Medium';
  }
  .rrss{
    display: flex;
    li{
      margin-right: 1rem;
    }
    svg{
      width: 18px;
      height: 18px;
      color: $basque-01;
    }
  }
}