/*
  # Reset
  # Outline
*/


// # Reset
.btn-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  //font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:focus {
    outline: none;
  }
}
// # Outline
.no-outline {
  &:focus {
    outline: none;
  }
}
// # Botones
.btn-basque-01{
  font-family: 'Montserrat Bold';
  background-color: transparent;
  color: $basque-01;
  font-size: 15px;
  text-transform: uppercase;
  border: 0;
}
.btn-basque-02{
  font-family: 'Montserrat Medium';
  background-color: transparent;
  color: $white;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid $white;
  border-radius: 0;
  &:hover{
    background-color: white;
    color: $black;
  }
}
.btn-basque-03{
  font-family: 'Montserrat Bold';
  position: relative;
  background-color: transparent;
  color: $basque-01;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  border: 0;
  padding: 0;
  &::before{
    content: "_";
    position: relative;
    top: -5px;
    opacity: 0.7;
    padding-right: 0.5rem;
  }
  &:hover{
    color: $basque-01;
  }
 }
 .btn-basque-04{
  font-family: 'Montserrat Medium';
  background-color: $basque-01;
  color: $white;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid $white;
  border-radius: 0;
  padding: 0.8rem 3rem;
  &:hover{
    background-color: $black;
  }
}