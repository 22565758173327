.main-text-legales{
  .container{
    padding: 2rem 0 4rem;
    h2{
      font-family: 'Montserrat Bold';
      margin-top: 4rem;
    }
    h3{
      font-family: 'Montserrat Bold';
      font-size: 1.2rem;
      color: $basque-01;
    }
    a{
      color: $basque-01;
    }
  }
}