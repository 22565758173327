
// Montserrat
@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Regular'), url('../theme/fonts/montserrat/Montserrat-Regular.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Italic'), url('../theme/fonts/montserrat/Montserrat-Italic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Thin'), url('../theme/fonts/montserrat/Montserrat-Thin.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Thin Italic'), url('../theme/fonts/montserrat/Montserrat-ThinItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraLight'), url('../theme/fonts/montserrat/Montserrat-ExtraLight.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraLight Italic'), url('../theme/fonts/montserrat/Montserrat-ExtraLightItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Light';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Light'), url('../theme/fonts/montserrat/Montserrat-Light.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Light Italic'), url('../theme/fonts/montserrat/Montserrat-LightItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Medium'), url('../theme/fonts/montserrat/Montserrat-Medium.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Medium Italic'), url('../theme/fonts/montserrat/Montserrat-MediumItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat SemiBold'), url('../theme/fonts/montserrat/Montserrat-SemiBold.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat SemiBold Italic'), url('../theme/fonts/montserrat/Montserrat-SemiBoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Bold'), url('../theme/fonts/montserrat/Montserrat-Bold.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Bold Italic'), url('../theme/fonts/montserrat/Montserrat-BoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraBold'), url('../theme/fonts/montserrat/Montserrat-ExtraBold.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraBold Italic'), url('../theme/fonts/montserrat/Montserrat-ExtraBoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Black';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Black'), url('../theme/fonts/montserrat/Montserrat-Black.woff') format('woff');
  }
  @font-face {
  font-family: 'Montserrat Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Black Italic'), url('../theme/fonts/montserrat/Montserrat-BlackItalic.woff') format('woff');
  }
.montserrat-regular{
    font-family: 'Montserrat Regular';
}
.montserrat-thin{
    font-family: 'Montserrat Thin';
}
.montserrat-extra-light{
    font-family: 'Montserrat ExtraLight';
}
.montserrat-light{
    font-family: 'Montserrat Light';
}
.montserrat-medium{
    font-family: 'Montserrat Medium';
}
.montserrat-semi-bold{
    font-family: 'Montserrat SemiBold';
}
.montserrat-bold{
    font-family: 'Montserrat Bold';
}
.montserrat-extra-bold{
    font-family: 'Montserrat ExtraBold';
}
.montserrat-black{
    font-family: 'Montserrat Black';
}
/* Poppins */
  @font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  src: url('../theme/fonts/poppins/Poppins-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../theme/fonts/poppins/Poppins-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../theme/fonts/poppins/Poppins-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../theme/fonts/poppins/Poppins-regular.woff') format('woff'), /* Modern Browsers */
       url('../theme/fonts/poppins/Poppins-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../theme/fonts/poppins/Poppins-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  @font-face {
  font-family: 'Poppins Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-Italic.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Thin';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-Thin.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-ThinItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Extra Light';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-ExtraLight.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Extra Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-ExtraLightItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-Light.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Light Italic'), url('../theme/fonts/poppins/Poppins-LightItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Medium';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-Medium.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-MediumItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Semi Bold';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-SemiBold.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Semi Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-SemiBoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-Bold.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-BoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Extra Bold';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-ExtraBold.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Extra Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-ExtraBoldItalic.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Black';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-Black.woff') format('woff');
  }
  @font-face {
  font-family: 'Poppins Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/poppins/Poppins-BlackItalic.woff') format('woff');
  }
.poppins{
  font-family: 'Poppins';
}
/* Roboto */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  src: url('../theme/fonts/roboto/roboto-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../theme/fonts/roboto/roboto-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../theme/fonts/roboto/roboto-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../theme/fonts/roboto/roboto-regular.woff') format('woff'), /* Modern Browsers */
       url('../theme/fonts/roboto/roboto-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../theme/fonts/roboto/roboto-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Roboto Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/roboto/Roboto-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/roboto/Roboto-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/roboto/Roboto-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Thin';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/roboto/Roboto-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/roboto/Roboto-ThinItalic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/roboto/Roboto-Light.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/roboto/Roboto-LightItalic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/roboto/Roboto-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/roboto/Roboto-MediumItalic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Black';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/roboto/Roboto-Black.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local(''), url('../theme/fonts/roboto/Roboto-BlackItalic.woff') format('woff');
}
.roboto{
  font-family: 'Roboto';
}